<template>
  <AdminPageFrame>
    <PageTitle>
      {{ trans('PAGE_TITLE:Translations') }}
    </PageTitle>
    <Loader v-if="translationsDataLoading" class="h-050screen"/>
    <t-card  v-else class="w-full">
      <div class="flex flex-col justify-evenly lg:justify-between w-full gap-3" v-if="translationsData">
	      <FieldWrapper :label="trans('LABEL:PostLanguage')">
		      <div class="flex flex-row col-span-3 justify-end">
			      <Button @clicked="saveTranslations" :text="trans('BUTTON:Save')" />
		      </div>
		      <div class="py-2 flex flex-row gap-3 w-full justify-between">
			      <div class="w-2/3">
				      <TextField v-model="searchValue" :placeholder="trans('LABEL:searchInput')" :custom-class="'pl-8'">
					      <template v-slot:iconFirst>
						      <base-icon class="absolute h-4 w-4 top-1/2 transform -translate-y-1/2 left-3" name="search">
						      </base-icon>
					      </template>
					      <template v-slot:iconEnd>
						      <CloseButton @clicked="clearSearchInput" :show="!isEmpty(searchValue)" />
					      </template>
				      </TextField>
			      </div>
			      <label class="flex flex-row gap-3 items-center cursor-pointer hover:text-redAccentLight hover:opacity-70">
				      <t-checkbox v-model="showOnlyMissing" name="show_only_missing" />
				      <span>{{ trans("LABEL:ShowOnlyMissing") }}</span>
			      </label>
		      </div>
	      </FieldWrapper>
	      <t-pagination
			      v-if="pagination"
			      class="mt-3"
			      variant="posts"
			      :total-items="pagination.total"
			      :per-page="pagination.per_page"
			      :limit="5"
			      v-model="currentPage"
			      @change="next"
	      ></t-pagination>
	      <DataTable :data="translationsData" :headers="tableHeaders">
					<template #pl="{item}">
						<t-input
								class="w-full"
								v-model="item.pl"
								@input="setNewTranslation($event, item.key, 'pl')"
						/>
					</template>
		      <template #en="{item}">
			      <t-input
					      class="w-full"
					      v-model="item.en"
					      @input="setNewTranslation($event, item.key, 'en')"
			      />
		      </template>
		      <template #uk="{item}">
			      <t-input
					      class="w-full"
					      v-model="item.uk"
					      @input="setNewTranslation($event, item.key, 'uk')"
			      />
		      </template>
	      </DataTable>
	      <t-pagination
			      v-if="pagination"
			      class="mt-3"
			      variant="posts"
			      :total-items="pagination.total"
			      :per-page="pagination.per_page"
			      :limit="5"
			      v-model="currentPage"
			      @change="next"
	      ></t-pagination>
        <div class="flex flex-row col-span-3 justify-end">
          <Button @clicked="saveTranslations" :text="trans('BUTTON:Save')" />
        </div>
      </div>
    </t-card>
  </AdminPageFrame>
</template>

<script>
import AdminPageFrame from "@/views/content/admin/components/AdminPageFrame.vue";
import Button from "@/utils/crud/components/Button";
import DataTable from "@/utils/table/DataTable";
import FieldWrapper from "@/utils/crud/components/field-types/components/FieldWrapper.vue";
import Loader from "@/views/Loader";
import PageTitle from "@/layouts/components/pages/PageTitle.vue";
import TextField from "@/utils/crud/components/field-types/Text.vue";

import api from "@/common/services/api";
import {mapActions, mapGetters} from "vuex";
import debounce from "lodash/debounce";
import CloseButton from "@/utils/crud/components/CloseButton.vue";
import BaseIcon from "@/utils/icons/BaseIcon.vue";
import {isEmpty} from "lodash/lang";

export default {
	name: "AdminTranslations",
	components: {
		BaseIcon, CloseButton,
    AdminPageFrame,
    Button,
    DataTable,
    FieldWrapper,
    Loader,
    PageTitle,
    TextField,
  },
	data() {
		return {
      translationsData: {
			},
			tableHeaders: [
				{
					name: 'LABEL:Key',
					value: 'key'
				},
				{
					name: 'LANGUAGE:pl',
					value: 'pl',
				},
				{
					name: 'LANGUAGE:en',
					value: 'en',
				},
				{
					name: 'LANGUAGE:uk',
					value: 'uk',
				},
			],
			translationsToChange: [],
      translationsDataLoading: true,
			currentPage: 1,
			pagination: {},
			searchValue: null,
			showOnlyMissing: false,
		}
	},
	computed: {
		...mapGetters('account', ['userData']),
	},
	watch: {
		searchValue: {
			handler(val) {
				this.searchTranslation(val);
			}
		},
		showOnlyMissing(val) {
			if(this.currentPage > 1 && this.showOnlyMissing) {
				this.currentPage = 1;
			}

			this.getTranslations();
		}
	},
	methods: {
		isEmpty,
		...mapActions('alert', ['success', 'error']),

		next(val) {
			this.currentPage = val;
			this.getTranslations(this.currentPage)
		},

		getTranslations(page = null) {
      this.translationsDataLoading = true;

			page = page ? page : this.currentPage;

			let url = `page=${page}&show_only_missing=${Number(this.showOnlyMissing)}`;

			if(!isEmpty(this.searchValue)) {
				url += `&query=${this.searchValue}`
			}


			api.setLocale();
			api.query(`/admin/translations?${url}`)
					.then(
							success => {

								this.translationsData = structuredClone(success.data);
								this.pagination = success.meta;
							},
							error => {

							}
					)
					.finally(() => {
            this.translationsDataLoading = false;
					}
			);
		},
		saveTranslations() {
			this.updateTranslation();
		},
		setNewTranslation: debounce(function (value, key, language) {
			if(this.translationsToChange.some(el => el.key === key && el.language === language)) {
				this.translationsToChange.find(el => el.key === key && el.language === language).value = value;
			} else {
				this.translationsToChange.push({key: key, value: value, language: language});
			}
		}, 200),

		searchTranslation: debounce(function (value) {
				this.searchValue = value;
				this.getTranslations(1)
		}, 1000),

		onlyUnique(value, index, array) {
			return array.indexOf(value) === index;
		},

		updateTranslation() {
			let data = {
				translations: this.translationsToChange,
			}

			api.post(`/admin/translations`, data)
					.then(
							success => {
								this.getTranslations(this.currentPage)
								this.success(success);
							},
							error => {
								this.error(error);
							}
					)
					.finally(() => {

							}
					);
		},
		clearSearchInput() {
			this.searchValue = null;
		}
	},
	mounted() {
		this.getTranslations(this.currentPage);
	}
}
</script>

<style scoped lang="scss">

</style>